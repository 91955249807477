import styled from 'styled-components';
import { Snippet } from 'react-instantsearch-dom';
import { mq } from '@utils/responsive';
import Link from '@components/Link';
import colors from '@utils/colors';
import { fontStyles } from '../type';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 10px;

  ${mq.gtsm`
    width: 300px;
  `}
`;

export const ImageContainer = styled.div`
  width: 100px;
  flex-shrink: 0;
`;

export const ContentContainer = styled.div`
  flex-grow: 1;
  text-align: left;
  padding: 0;
`;

export const FeaturedWrapper = styled.div`
  width: 100%;
  margin: 10px;

  ${mq.gtsm`
    width: 300px;
  `}
`;

export const FeaturedImageContainer = styled.div`
  width: 100px;
`;

export const FeaturedContentContainer = styled.div`
  text-align: left;
`;

export const StyledLink = styled(Link)`
  ${fontStyles.h5};
`;

export const StyledFeaturedLink = styled(Link)`
  ${fontStyles.h3};
`;

export const DataRow = styled.div`
  margin: 0;
`;

export const DataCol = styled.div`
  display: inline;
  font-size: 12px;
  margin: 0 10px 0 0;
`;

export const MhsSnippet = styled(Snippet)`
  &:before, &:after {
    content: '...';
  }

  .ais-Snippet-highlighted {
    font-style: italic;
  }
`;

export const CardInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${mq.gtsm`
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: -16px;
      border-right: 3px solid ${colors.B100};
      transform: translateX(1.5px);
    }
  `}
`;

